@font-face {
  font-family: 'Cafe24Dongdong';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Dongdong.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cafe24Oneprettynight';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Oneprettynight.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Cafe24Ohsquare';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Cafe24Ohsquare.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  /* font-family: 'NanumSquare', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  width: 100%;
  height: calc(100% - constant(safe-area-inset-top));
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  background-color: #fff;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}
* {
  box-sizing: border-box;
}
/* 버튼 */
button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
}
button:disabled {
  cursor: none;
  pointer-events: none;
}
.react-tooltip {
  padding: 0 !important;
  opacity: 1 !important;
}
.moveable-line {
  background: #6f6f6f !important;
}
.moveable-control {
  background-color: #fff !important;
  border: 2px solid #707070 !important;
}
.moveable-rotation .moveable-rotation-control {
  background-color: #6f6f6f !important;
  border-color: #b1b1b1 !important;
}
.moveable-rotation .moveable-rotation-control::after {
  content: '';
  position: absolute;
  top: -9px;
  right: -10px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/img/icon-lotation.svg');
  width: 15.5px;
  height: 23.5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
