.header{
    width: 100%;
    height: 7vh;
    /* background-color: antiquewhite; */
    position: relative;
    margin-bottom: 14vh;
}
.header img{
    position: absolute;
    width: 3vw;
    left: 4vw;
    top: 4.5vw;
}
.header .title{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5vw;

}

.boxWrap{
    width: 100%;
    height: 95vh;
    /* background-color: aqua; */
}
.boxWrap .letterBox .comment1{
    width: 85%;
    margin: 0 auto;
    font-size: 5vw;
    margin-bottom: 2vw;
}
.boxWrap .letterBox .comment2{
    width: 85%;
    margin: 0 auto;
    font-size: 3.3vw;
    margin-bottom: 8vw;
    color: #b7b7b7;
}
.boxWrap .detail{
    display: block;
    margin: 0 auto;
    width: 85%;
    height: 13vw;
    margin-bottom: 3vw;
    border: solid 2px #f0f0f0;
    color: #b7b7b7;
    padding-left: 3vw;
}
.boxWrap .btn{
    width: 85%;
    display: block;
    margin: 0 auto;
    margin-bottom: 4vw;
    height: 13vw;
    background-color: #dddddd;
    border: none;
    font-size: 4.5vw;
    font-weight: bold;
}
.alarm{
    text-align: center;
    margin-top: 3vw;
}