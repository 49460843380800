@media (max-width: 1100px) {
  .title {
    text-align: center;
    padding: 3.8vw 0;
    font-weight: bold;
    font-size: 5vw;
    position: relative;
  }
  .title img {
    width: 8vw;
    position: absolute;
    left: 3vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .listWrap {
    padding-top: 17vh;
  }
  .list {
    width: 80vw;
    height: 12vw;
    line-height: 11vw;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 5vw;
    border: solid 1px #6f6f6f;
    font-size: 5vw;
  }
  .list:hover {
    color: white;
    background-color: #e89b7f;
    border: none;
  }
}
@media (min-width: 1100px) {
  .title {
    text-align: center;
    padding: 30px 0;
    font-weight: bold;
    font-size: 30px;
    position: relative;
  }
  .title img {
    width: 40px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .listWrap {
    padding: 17vh 0;
  }
  .list {
    width: 30vw;
    height: 7vw;
    line-height: 7vw;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 5vw;
    border: solid 1px #6f6f6f;
    font-size: 3vw;
  }
  .list:hover {
    color: white;
    background-color: #e89b7f;
    border: none;
  }
}
