.Modal{
    background: rgba(0, 0, 0, 0.15);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.Modal .content{
    background: white;
    border-radius: 9px;
    width: 270px;
    height: 150px;
    z-index: 3;
    text-align: center;
    padding-top: 40px;
    padding-left: 8px;
    padding-right: 8px;
}
.Modal .content .letter{
    margin-bottom: 25px;
}
.Modal .content button{
    width: 25%;
    height: 25px;
    background-color: #6f6f6f;
    color: white;
    border-radius: 12px;

}