@media (min-width: 1100px) {
  .title {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  }
  .title .back {
    width: 15px;
    cursor: pointer;
  }
  .title .btn {
    background-color: #e89b7f;
    width: 150px;
    height: 50px;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}
@media (max-width: 1100px) {
  .title {
    padding-top: 5vw;
    font-weight: bold;
    font-size: 5vw;
    position: relative;
    margin-bottom: 10vw;
  }
  .title .back {
    width: 3vw;
    position: absolute;
    left: 4vw;
    top: 4.5vw;
    cursor: pointer;
  }
  .title .btn {
    position: absolute;
    right: 5vw;
    top: 3vw;
    background-color: #e89b7f;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 2vw;
    padding-bottom: 1.7vw;
    border-radius: 3vw;
    font-size: 5vw;
    color: white;
    cursor: pointer;
  }
}
