.mainWrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100%;
  text-align: center;
}
.logo img {
  margin-top: 8vh;
  width: 35%;
  max-width: 200px;
}
.logo .title {
  font-size: 15px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .logo .title {
    font-size: 25px;
  }
}
.body {
  position: absolute;
  top: 30vh;
  width: 100%;
  height: calc(100% - 40vh);
  text-align: center;
  overflow: hidden;
}
.body img {
  width: 70%;
  min-width: 300px;
  max-width: 440px;
}

.footer {
  position: relative;
  width: 100%;
  margin-top: auto;
}
.footer .line1,
.footer .line2,
.footer .line3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .footer .line1,
  .footer .line2,
  .footer .line3 {
    font-size: 22px;
  }
}
.footer .line1 {
  background-color: black;
}
.footer .line2 {
  background-color: #6f6f6f;
}
.footer .line3 {
  background-color: #e89b7f;
}
