.horizontal_slider {
  display: block;
  width: 100%;
  overflow-x: scroll;
}
.slider_container {
  display: block;
  white-space: nowrap;
}

@media (min-width: 1100px) {
  .title {
    text-align: center;
    padding: 30px 0;
    font-weight: bold;
    font-size: 30px;
    position: relative;
    margin-bottom: 20vh;
  }
  .title .back {
    width: 15px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .title .home {
    width: 40px;
    position: absolute;
    right: 4vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .item {
    /* display: inline-block; */
    margin-left: 5vw;
    margin-right: 3vw;
    width: 200px;
    border-radius: 20px;
    margin-bottom: 5vw;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  }
}
@media (max-width: 630px) {
  .title {
    text-align: center;
    padding-top: 5vw;
    font-weight: bold;
    font-size: 5vw;
    position: relative;
    margin-bottom: 20vh;
  }
  .title .back {
    width: 3vw;
    position: absolute;
    left: 4vw;
    top: 4.5vw;
    cursor: pointer;
  }
  .title .home {
    width: 8vw;
    position: absolute;
    right: 4vw;
    top: 3vw;
    cursor: pointer;
  }
  .item {
    margin-left: 5vw;
    margin-right: 3vw;
    width: 65vw;
    border-radius: 20px;
    margin-bottom: 5vw;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  }
}
@media (max-width: 1100px) and (min-width: 630px) {
  .title {
    text-align: center;
    padding-top: 5vw;
    font-weight: bold;
    font-size: 5vw;
    position: relative;
    margin-bottom: 20vh;
  }
  .title .back {
    width: 3vw;
    position: absolute;
    left: 4vw;
    top: 4.5vw;
    cursor: pointer;
  }
  .title .home {
    width: 8vw;
    position: absolute;
    right: 4vw;
    top: 3vw;
    cursor: pointer;
  }
  .item {
    margin-left: 5vw;
    margin-right: 3vw;
    width: 45vw;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
  }
}
